import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Accordion, AccordionContext, Card, Modal, useAccordionButton } from "react-bootstrap"
import parse from "html-react-parser"
import ComponentButton from "../../components/ComponentButton"
import useMutate from "../../../api/hooks/useMutate"
import { useIntl } from "react-intl"
import { Formik, Form as FormikForm } from "formik"
import ComponentTextEditor from "../../components/ComponentTextEditor"
import setModalIndex from "../../helpers/setModalIndex"
import unescape from "lodash/unescape"
import { Pagination } from "../ModuleList/src/Pagination"
import useListData from "../../../api/hooks/useListData"
import { TModuleAccordion, TModuleAccordionItem } from "./_types"
import ComponentDashboard from "../../components/ComponentDashboard";
import { ModuleContext } from "../helpers/ModuleContext";
import printJS from "print-js";
import print from "../../helpers/print"
import SplashScreen from "../../helpers/SplashScreen"
import generatePdf from "../../helpers/generatePdf"
import SkeletonAccordion from "../src/SkeletonAccordion"


const PdfView = React.lazy(() => import("./src/PdfView"));


const AccordionView: React.FC<{ view: string, body?: string, href?: string }> = ({ view, body, href }) => {
    switch (view) {
        case "native":
            return <div>{parse(unescape(body))}</div>
        case "pdf":
            return <PdfView href={href} />
        case "image":
            return <div className="moduleAccordion_itemPreview image"><img src={href} /></div>
        default:
            return <div className="moduleAccordion_itemPreview default">
                <span>Предварительный просмотр недоступен, пожалуйста, перейдите по <a href={href} target="_blank">ссылке</a></span>
            </div>
    }
}

const PdfButton: React.FC<{ title: string, body: string }> = ({ title, body }) => {
    const [loading, setLoading ] = useState(false)
    return <ComponentButton
        type="custom"
        settings={{ title: "PDF", icon: "files", background: "light" }}
        defaultLabel="icon"
        customHandler={() => generatePdf({ title, body: unescape(body), setLoading })}
        loading={loading}
    />
}

const AccordionItem: React.FC<TModuleAccordionItem> = ({ id, title, body, href, user_id, mutate, handleEdit }) => {
    const intl = useIntl()
    const eventKey = String(id)
    const { activeEventKey } = useContext(AccordionContext)
    const isCurrentEventKey = activeEventKey === eventKey;

    const handleAccordionToggle = useAccordionButton(eventKey)
    const handleTitleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        return event.target instanceof Element && !event.target.closest(".componentButton") ? handleAccordionToggle(event) : null
    }

    const handleDelete = () => mutate({ id })

    const view = useMemo(() => {
        if (body) {
            return "native"
        } else if (href) {
            if (href.includes("pdf")) {
                return "pdf"
            } else if (href.includes("jpg") || href.includes("jpeg") || href.includes("png")) {
                return "image"
            }
        }
        return "link"
    }, [body, href])

    const handlePrint = useCallback(() => {
        switch (view) {
            case "native":
                return print(unescape(body))
            case "pdf":
            case "image":
                return printJS({ printable: href, type: view })
            default:
                return
        }
    }, [body, href, view])

    const handleShow = () => {
        const link = document.createElement('a')
        link.href = href as string
        link.target = "_blank"
        document.body.appendChild(link)
        link.click()
        return link.remove()
    }

    return <Card className="moduleAccordion_item">
        <Card.Header className={`moduleAccordion_itemHeader${isCurrentEventKey ? " active" : ""}`} onClick={handleTitleClick}>
            <Card.Title className="moduleAccordion_itemTitle">
                {`${title}${user_id ? ` - ${user_id.title}` : ""}`}
            </Card.Title>
            <div className="moduleAccordion_itemButtons">
                {
                    body
                        ?
                        <>
                            <PdfButton title={title} body={body} />
                            <ComponentButton
                                type="custom"
                                settings={{ title: intl.formatMessage({ id: "BUTTON.EDIT" }), icon: "edit", background: "light" }}
                                defaultLabel="icon"
                                customHandler={() => handleEdit({ id, body })} />
                        </>
                        :
                        <ComponentButton
                            type="custom"
                            settings={{ title: intl.formatMessage({ id: "BUTTON.OPEN" }), icon: "glasses", background: "light" }}
                            defaultLabel="icon"
                            customHandler={handleShow} />

                }

                {view !== "link" ? <ComponentButton
                    type="custom"
                    settings={{ title: intl.formatMessage({ id: "BUTTON.PRINT" }), icon: "print", background: "light" }}
                    defaultLabel="icon"
                    customHandler={handlePrint} /> : null
                }

                <ComponentButton
                    type="custom"
                    settings={{ title: intl.formatMessage({ id: "BUTTON.DELETE" }), icon: "trash", background: "light", attention_modal: true }}
                    defaultLabel="icon"
                    customHandler={handleDelete}
                />
            </div>
        </Card.Header>
        <Accordion.Collapse eventKey={eventKey}>
            <Card.Body className="moduleAccordion_itemBody">
                <React.Suspense fallback={<SplashScreen active />}>
                    <AccordionView view={view} body={body} href={href} />
                </React.Suspense>
            </Card.Body>
        </Accordion.Collapse>
    </Card>
}

const ModuleAccordion: React.FC<TModuleAccordion> = ({ settings, components }) => {
    const intl = useIntl()
    const { object, property_title, property_body, filters } = settings
    const initialFilters = Object.assign({ select: ["title", "body", "user_id"] }, filters ?? {})
    const [filter, setFilter] = useState<{ page: number, limit: number }>({ page: 1, limit: 20 })
    const { data, isLoading, refetch } = useListData(object, Object.assign(filter, initialFilters))
    const { mutate, isSuccess } = useMutate(object, "remove")
    const { mutate: updateItem, isSuccess: isUpdateSuccess } = useMutate(object, "update")
    const [editableItem, setEditableItem] = useState<{ id: number, body: string } | null>(null)

    const handleEdit = useCallback((document: { id: number, body: string }) => setEditableItem(document), [])

    const showPagination = data?.data.length
    const haveButtons = Boolean(components?.buttons)

    useEffect(() => {
        if (isSuccess || isUpdateSuccess) {
            setEditableItem(null)
            refetch()
        }
    }, [isSuccess, isUpdateSuccess])

    const contextValue = useMemo(() => ({
        refresh: refetch
    }), [])

    return <ModuleContext.Provider value={contextValue}>
        <ComponentDashboard>
            {haveButtons ? components.buttons.map(button => <ComponentButton key={button.settings.title} {...button} />) : null}
        </ComponentDashboard>
        <div className="moduleAccordion">
            {isLoading ? <SkeletonAccordion /> : null}
            <Accordion>
                {data?.data.map(document => <AccordionItem
                    key={document.id}
                    id={document.id}
                    title={document[property_title]}
                    body={document[property_body]}
                    href={document.href}
                    user_id={document.user_id}
                    mutate={mutate}
                    handleEdit={handleEdit}
                />)}
            </Accordion>
            {showPagination ? <Pagination detail={data?.detail} filter={filter} setFilter={setFilter} /> : null}

            <Modal show={Boolean(editableItem)} onHide={() => setEditableItem(null)} size="xl" onEntering={setModalIndex} enforceFocus={false}>
                <Modal.Header>
                    <Modal.Title>
                        {intl.formatMessage({ id: "MODAL.EDIT_TITLE" })}
                    </Modal.Title>
                </Modal.Header>
                <Formik initialValues={editableItem ?? {}} onSubmit={values => updateItem(values)}>
                    {({ handleSubmit }) => <FormikForm>
                        <Modal.Body>
                            <ComponentTextEditor article="body" />
                        </Modal.Body>
                        <Modal.Footer>
                            <ComponentButton
                                type="submit"
                                settings={{ title: intl.formatMessage({ id: "BUTTON.CANCEL" }), icon: "", background: "light" }}
                                customHandler={() => setEditableItem(null)} />
                            <ComponentButton
                                type="submit"
                                settings={{ title: intl.formatMessage({ id: "BUTTON.SUBMIT" }), icon: "", background: "dark" }}
                                customHandler={handleSubmit} />
                        </Modal.Footer>
                    </FormikForm>
                    }
                </Formik>
            </Modal>
        </div>
    </ModuleContext.Provider>
}

export default ModuleAccordion